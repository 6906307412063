.select input {
    font-family: 'Hind Guntur';
    font-weight: 600;
    /* top: 60% !important; */
    /* padding-top:20px !important; */
    letter-spacing: -1px;
    color: #2e2e2e;
    padding: 0;
    margin: 0;
    line-height:-30px !important;
}

.select{
    width: 100%;
}

