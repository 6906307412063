.ExploreHeader_Wrapper {
  position: absolute;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 68px;
  z-index: 20;
  width: 100%;
  background: grey;
}

.ExploreHeader_Container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  height: 100%;
}

.ExploreHeader_Container > button {
  margin: 0;
}

.ExploreHeader_Navigation {
  display: flex;
  height: 30px;
}

.ExploreHeader_Selector {
  width: 355px;
  margin-right: 30px;
  margin-top: -25px;
}



.DrawerToggle {
  width: 35px;
  height: 4px;
  background-color: black;
  margin: 8px 0;
}

.button {
  background-color: transparent;
  color: transparent;
  border-color: transparent;
}

.mobilebutton {
  display: none;
}

@media screen and (max-width: 1340px) {
  .mobilebutton {
    display: flex;
    align-items: right;
  }
}
