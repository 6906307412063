.Mixer {
  display: flex;
  flex-flow: row;
  margin-bottom: 30px;
}
.Mixer > * {
  flex: 1 1;
}
.Mixer > div:nth-child(2) {
  margin-left: -1px;
}

@media screen and (max-width: 959px) {
  .Mixer {
    flex-flow: column;
  }
  .Mixer > div:nth-child(2) {
    margin-left: 0px;
    margin-top: -1px;
  }
}
