.MenuHeader {
  display: block;
  height: 64px;
  width: 100%;
  /* background-color: white; */
  background-color: #eac070;
}
.MenuHeader > header {
  height: 100%;
}
.MenuList {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
}
.MenuList > li {
  margin-right: 50px;
  cursor: pointer;
}
.MenuItem {
  height: 100%;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  position: relative;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
  font-weight: 600;
}
.active::before {
  content: "";
  height: 4px;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: #2e2e2e;
}
@media screen and (max-width: 1024px) {
  .MenuHeader {
    height: 48px;
  }

  .active::before {
    height: 4px;
    top: 44px;
  }

  .MenuItem {
    font-size: 1.2rem;
    letter-spacing: -0.5px;
  }

  .MenuList > li {
    margin-right: auto;
  }
}

@media screen and (max-width: 448px) {
  .MenuItem {
    font-size: 1.1rem;
  }
}
