.FooterWrapper {
  height: 300px;
  width: 100vw;
  background-color: lightgray;
}
.Footer {
  margin: 0 auto 0 auto;
  height: 100%;
  display: flex;
  flex-flow: row;
}
.FooterContent {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  height: 100%;
}
.FooterHeader {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  
}
.FooterHeader span {
  font-size: 0.8rem;
  font-weight: bold;  
}
.FooterLogo {
  width: 50%;
  
}
.FooterIcons > a:not(:first-child) {
  margin-left: 10px;
}
.FooterText {
  width: 80%;
  line-height: 1.5rem;
}
@media screen and (min-width: 1480px) {
  .Footer {
    width: 1384px;
  }
}
/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Footer {
    width: calc(100vw - 92px);
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .Footer {
    width: calc(100vw - 72px);
  }
}
/* Tablets */
@media screen and (min-width: 730px) and (max-width: 959px) {
  .Footer {
    width: calc(100vw - 72px);
  }
}
/* MOBILE */
@media screen and (max-width: 729px) {
  .Footer {
    width: calc(100vw - 48px);
    flex-flow: column;
    padding: 40px 0;
  }
  .FooterWrapper {
    height: unset;
  }
  .FooterText {
    width: 100%;
    margin-bottom: 20px;
  }
}
