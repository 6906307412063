.HeaderWrapper {
  height: 100%;
  width: 100%;
}
/* 
.HeaderWrapper::before {
    content:"";
    top: calc(20vw + 280px) ;
    position: absolute;
    background:#e3e3e3;
    height: 1px;
    width: 100%;
} */

.MapWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1279px) {
  .HeaderWrapper {
    /* height: 400px; */
  }

  .MapWrapper {
    display: block;
    margin-top: 500px;
    margin-left: 200px;
    margin-right: 20px;
    width: 70%;
    height: 500px;
    outline-style: solid;
    outline-width: 1px;
  }
}
