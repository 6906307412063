.ExploreHeader_Wrapper {
    position: fixed;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 68px;
    z-index: 20;
    width: 100%;
    background: #eac070;

}

.ExploreHeader_Container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0px 20px;
    height: 100%;
}

.ExploreHeader_Navigation{

    display: flex;
    height: 30px;

}

.ExploreHeader_Selector {
    width: 355px;
    margin-right: 30px;
    margin-top: -25px;
}

.ExploreHeader_Timeslider {
    width: 300px;
    margin-right: 30px;
    display: flex;

}

.ExploreHeader_Popselector {
    width: 1050px;
    margin-right: 30px;
    margin-bottom: 30px;

}

.HeaderItem{
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
}

.selected {
  border-bottom: 3px solid #11bb9a;
  color: #11bb9a;
  margin-left:30px;
  margin-right:30px;
}

.unselected {
  margin-left:30px;
  margin-right:30px;
}

.DrawerToggle {
    width: 35px;
    height: 4px;
    background-color: black;
    margin: 8px 0;
}

.button {
  background-color:transparent;
  color:transparent;
  border-color:transparent;
}


@media screen and (max-width: 1279px)  {
    .Index {
    display:none
    }
}

@media screen and (max-width: 600px)  {
    .ExploreHeader_Selector {
    display:none
    }
}

