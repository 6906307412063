.Container {
    display: grid;
    padding: 70px 0;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 35px auto;
    grid-auto-flow: dense;
    gap: 20px 20px;
}

.ResearchItem2 > a > h4 {
    margin: 0;
    line-height: 1em;
    letter-spacing: -1px;
    font-size: 2rem;
}

.ResearchItem2:hover h4 {
    text-decoration: underline;
}

.ResearchItem2 > a {
    display: flex;
    flex-flow: column;
    color: #2e2e2e;
}

.ResearchItem2 > a > p {
    margin: 0;
    font-size: 1.1rem;
    margin-top: 10px;
    line-height: 23px;
}

.ResearchItem2 {
    grid-column: span 12;
}

@media screen and (max-width: 1023px) {
    .Container {
        padding: 30px 0;
        grid-template-columns: repeat(18, 1fr);
    }
}

@media screen and (max-width: 729px)  {
    .ResearchItem2 > a > h4 {
        font-size: 1.6rem;
    }
    .ResearchItem2 > a > p {
        margin: 0;
        font-size: 1rem;
        margin-top: 5px;
        line-height: 20px;
    }

    .ResearchItem2 {
        grid-column: 1 / span 18;
    }
}

.ResearchItem > a > h4 {
    margin: 0;
    line-height: 1em;
    letter-spacing: -1px;
    font-size: 2rem;
}

.ResearchItem:hover h4 {
    text-decoration: underline;
}

.ResearchItem > a {
    display: flex;
    flex-flow: column;
    color: #2e2e2e;
}

.ResearchItem > a > p {
    margin: 0;
    font-size: 1.1rem;
    margin-top: 10px;
    line-height: 23px;
}

.ResearchItem {
    grid-column: span 6;
}

@media screen and (max-width: 1023px) {
    .Container {
        padding: 30px 0;
        grid-template-columns: repeat(18, 1fr);
    }
}

@media screen and (max-width: 729px)  {
    .ResearchItem > a > h4 {
        font-size: 1.6rem;
    }
    .ResearchItem > a > p {
        margin: 0;
        font-size: 1rem;
        margin-top: 5px;
        line-height: 20px;
    }

    .ResearchItem {
        grid-column: 1 / span 18;
    }
}



