.MainContainer {
  display: grid;
  /* padding-bottom: 100px; */
  padding-bottom: calc(10vw);
  padding-bottom: 6vw;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 35px;
  grid-auto-flow: dense;
  gap: 0px 16px;
}
.Container {
  display: grid;
  padding: 35px 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 35px auto auto;
  grid-auto-flow: dense;
  gap: 0px 16px;
}
.EventsContainer{
  display: grid;
  padding: 35px 0;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 35px auto auto;
  grid-auto-flow: dense;
  gap: 0px 16px;
}


.subscribe{
  grid-column: 3 span;
  margin-left: 5%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.5rem;
}
.subscribe a{
  font-size: 1rem;
  padding: 5px 10px 2px 10px;
  text-decoration: none;
  color: #000;
  background: #eac070;
}

.showMoreButton{
  background: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 10px;
  width: 40%;
  max-width: 250px;
  cursor: pointer;
}

.Item {
  padding-top: 40px;
  grid-column: 3 span;
}

.Item > a {
  color: #6a6a6a;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.2px;
  width: 100%;
}

.Item > a > h4 {
  margin: 0;
  line-height: 1em;
  letter-spacing: -1px;
  font-size: 2rem;
  color: #2e2e2e;
}

.Item > a > p {
  font-size: 1rem;
  margin: 20px 0 0 0;
  line-height: 1em;
}

.Item:hover a h4 {
  text-decoration: underline;
}

@media screen and (min-width: 1480px) {
  .MainContainer {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 729px) {
  .Container {
    padding: 30px 0;
    grid-template-columns: repeat(1, 1fr);
  }
  .MainContainer {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .Container {
    grid-template-rows: 36px auto auto auto auto;
  }
  .MainContainer {
    grid-template-rows: auto auto auto;
  }
}
