.ExploreWrapper h1 {
  margin: 0;
}

.MapWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ExploreNavToggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.button_collapse{
  width: 0px;
  height: 0px;
  position: fixed;
  z-index: 15;
  left: 345px;
  top : 50%;
  border: 10px solid white;
  border-top-color: white;
  border-left-color: white;
  border-bottom-color: white;
  border-right-color: #000000;
}
.button_collapse:hover{
  color: white;
  width: 0px;
  height: 0px;
  border-top-color: white;
  border-left-color: white;
  border-bottom-color: white;
  border-right-color: #dbdbdc;
}

.button_expand{
  width: 0px;
  height: 0px;
  position: fixed;
  z-index: 15;
  left: 5px;
  top : 50%;
  border: 10px solid white;
  border-top-color: transparent;
  border-left-color: #000000;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.button_expand:hover{
  color: white;
  width: 0px;
  height: 0px;
  border-top-color: transparent;
  border-left-color: #dbdbdc;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
