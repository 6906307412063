.KeyFigureItem {
    color: #2e2e2e;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.KeyFigureItem span {
    display: inline-block;
    max-width: 150px;
    line-height: 1em;
    font-size: .9em;
}

.KeyFigureItem h6 {
    font-size: 1.7rem;
    line-height: 1em;
    margin: 20px 0 0 0;
}

.KeyFigureItem h6 > span {
    display: inline;
    font-weight: 500;
    font-size: .6em;
}

@media screen and (max-width: 1279px)  {
    .KeyFigureItem h6 {
        font-size: 23px;
        line-height: 1em;
        margin: 20px 0 0 0;
    }
}
