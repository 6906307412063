.ArticleText {
  font-size: 1.3rem;
  grid-column: 4 / span 6;
}

.ArticleText  h5 {
  font-size: 2rem;
  letter-spacing: -0.8px;
  line-height: 1em;
  margin: 20px 0;
}


.ArticleText  p {
  margin: 0 0 50px 0;
  letter-spacing: -0.003em;
  font-size: 1.2rem;
  line-height: 1.7em;
}

.ArticleChart {
  grid-column: 1 / span 12;
  width: 100%;
  height: 500px;
  background: #e2e2e2;
  margin: 30px 0;
}

.ArticleImage {
  position: relative;
  grid-column: 1 / span 12;
  width: 100%;
  height: 500px;
  margin: 30px 0;
}
.image__inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image__inner img {
  width: 100%;
  height: auto;
}

.ArticleAuthor {
  position: relative;
  grid-column: 11 / span 2;
  font-size: 1.1rem;
  letter-spacing: -0.8px;
  line-height: 1em;
  margin: 20px 0;
}


@media screen and (min-width: 730px) and (max-width: 1024px) {
  .ArticleText {
    grid-column: 2 / span 10;
  }
  .ArticleImage {
    height: 400px;
  }
  .ArticleAuthor {
    position: relative;
    grid-column: 1 / span 12;
    font-size: 1.1rem;
    letter-spacing: -0.8px;
    line-height: 1em;
    margin: 20px 0;
  }
}
@media screen and (max-width: 729px) {
  .ArticleText {
    grid-column: 1 / span 12;
  }
  .ArticleImage {
    height: 250px;
  }
  .ArticleAuthor {
    position: relative;
    grid-column: 1 / span 12;
    font-size: 1.1rem;
    letter-spacing: -0.8px;
    line-height: 1em;
    margin: 20px 0;
  }
}



/*** Table Styles **/


.MarkdownText table-fill {
  background: white;
  border-radius:3px;
  border-collapse: collapse;
  /*height: 320px;*/
  margin: auto;
  max-width: 600px;
  padding:5px;
  width: 100%;
  height:auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
}

.MarkdownText horizontal {
  border-collapse: separate;
  border-spacing: 5px;
  border: 1px solid #000;
}

.MarkdownText th {
  color:#D5DDE5;;
  background:#1b1e24;
  border-bottom:3px solid #9ea7af;
  border-right: 1px solid #343a45;
  /*font-size:20px;*/
  font-weight: 100;
  padding:10 px;
  text-align:Center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

.MarkdownText th:first-child {
  border-top-left-radius:5px;
}

.MarkdownText th:last-child {
  border-top-right-radius:5px;
  border-right:none;
}

.MarkdownText tr {
  border-top: 1px solid #C1C3D1;
  border-bottom-: 1px solid #C1C3D1;
  color:#666B85;
  font-size:16px;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

.MarkdownText tr:hover td {
  background:#4E5066;
  color:#FFFFFF;
  border-top: 1px solid #22262e;
}

.MarkdownText tr:first-child {
  border-top:none;
}

.MarkdownText tr:last-child {
  border-bottom:none;
}

.MarkdownText tr:nth-child(odd) td {
  background:#EBEBEB;
}

.MarkdownText tr:nth-child(odd):hover td {
  background:#4E5066;
}

.MarkdownText tr:last-child td:first-child {
  border-bottom-left-radius:5px;
}

.MarkdownText tr:last-child td:last-child {
  border-bottom-right-radius:5px;
}

.MarkdownText td {
  background:#FFFFFF;
  /*padding:5px;*/
  text-align:center;
  vertical-align:middle;
  /*font-weight:300;*/
  /*font-size:18px;*/
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  /*border-right: 1px solid #C1C3D1;*/
}

.MarkdownText td:last-child {
  border-right: 0px;
}

.MarkdownText th.text-left {
  text-align: left;
}

.MarkdownText th.text-center {
  text-align: center;
}

.MarkdownText th.text-right {
  text-align: right;
}

.MarkdownText td.text-left {
  text-align: left;
}

.MarkdownText td.text-center {
  text-align: center;
}

.MarkdownText td.text-right {
  text-align: right;
}


/*** Line separator Styles **/
.MarkdownText hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 2rem 0 2.5rem;
}

/*** Bullet point Styles **/
.MarkdownText ul {
  list-style: square;
  letter-spacing: -0.003em;
  font-size: 1.1rem;
  line-height: 1.7em;
}
.MarkdownText li {
  letter-spacing: -0.003em;
  font-size: 1.1rem;
  line-height: 1.7em;
}
/*** Hyperlink Styles **/

.MarkdownText  a {
  color: #2e2e2e;
  text-decoration: underline;
}

.MarkdownText  a:hover {
  color: #2e2e2e;
  text-decoration: underline;
}

/*** MarkdownText Styles **/
.MarkdownText {
  font-size: 1.3rem;
  grid-column: 4 / span 6;
}

.MarkdownText  h5 {
  font-size: 1.2rem;
  letter-spacing: -0.8px;
  line-height: 1em;
  margin: 20px 0;
}

.MarkdownText  h3 {
  font-size: 1.7rem;
  letter-spacing: -0.8px;
  line-height: 1em;
  margin: 20px 0;
}

/*added */
.MarkdownText  h6 {
  font-size: 1rem;
  letter-spacing: -0.8px;
  line-height: 1em;
  margin: 20px 0;
  margin: 0 0 30px 0;
}

.MarkdownText  p {
  margin: 10px 0 10px 0;
  letter-spacing: -0.003em;
  font-size: 1.1rem;
  line-height: 1.7em;
}

.MarkdownText img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 730px) and (max-width: 1024px) {
  .MarkdownText {
    grid-column: 2 / span 10;
  }
  .ArticleImage {
    height: 400px;
  }
}
@media screen and (max-width: 729px) {
  .MarkdownText {
    grid-column: 1 / span 12;
  }
  .ArticleImage {
    height: 250px;
  }
}
