.SearchBar {
  flex: 1 1;
}
@media (max-width: 752px) {
  .SearchBar {
    width: 50%;
  }
}

.SearchBar__Sm {
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .SearchBar__Sm {
    width: 100%;
  }
}
