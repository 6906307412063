.urbandynamics {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
}

.table {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5rem;
  font-family: "Hind Guntur", san-serif;
}

.table > thead > tr > th {
  border-bottom: 3px solid #ddd;
  text-align: center;
}

.graph {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.legendtable {
  display: flex;
  width: 20%;
  text-align: center;
  vertical-align: middle;
  position: relative;
  flex-flow: column;
}

.legendtable > p {
  font-family: "Platform";
  line-height: 45px;
  font-size: 2.7rem;
  color: #366591;
  font-weight: 400;
}

.legendtable > span {
  font-family: "Platform";
  line-height: 5px;
  font-size: 1.5rem;
}

.button {
  font-size: 0.8rem;
  font-family: "Hind Guntur", san-serif;
  border: none;
  padding: 7px 15px 4px 15px;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.7px;
  transition: all 0.19s linear;
  background-color: rgba(45, 45, 45, 0.1);
  outline: none;
  cursor: pointer;
}

.button:hover {
  color: #ffffff;
  background-color: rgba(45, 45, 45, 0.7);
  transition: all 0.19s linear;
}

.buttonActive {
  font-size: 0.8rem;
  font-family: "Hind Guntur", san-serif;
  border: none;
  padding: 7px 15px 4px 15px;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.7px;
  cursor: pointer;
  transition: all 0.19s linear;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.19s linear;
  outline: none;
}

.close {
  font-size: 1rem;
  font-family: "Hind Guntur", san-serif;
  border: none;
  padding: 1px 9px 1px 9px;
  border-radius: 300px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: "grey";
  border: 2px solid "grey";
  color: white;
  transition: all 0.19s linear;
}

.buttonrow {
  width: 100%;
  margin-bottom: 20px;
}

.popdiv {
  visibility: visible;
  width: 100%;
}

.hiddendiv {
  display: none;
}

.Tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 400;
}

/* MOBILE */
@media screen and (min-width: 850px) and (max-width: 1024px) {
  .table {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 849px) {
  .table {
    display: none;
  }
}
