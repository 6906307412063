main {
  width: 100vw;
}
.Hero {
  width: 100%;
  background: #fff;
  height: calc(20vw + 340px);
}
.HeroText {
  width: 100%;
  background-color: #eac070;
  height: calc(20vw + 200px);
}
.expand {
  background-image: linear-gradient(to bottom right, #eac070, #c3533e);
}
.Text_TitleWrapper {
  width: 100%;
  background-color: #eac070;
}

.HeroText::before {
  content: "";
  top: calc(20vw + 200px);
  position: absolute;
  height: 1px;
  width: 100%;
}
.Hero__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.Text_TitleContainer {
  display: block;
  position: relative;
  align-items: center;
  height: 100%;
  z-index: 2;
}

/* Header Wrapper */
.Header {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  z-index: 2;
}
/* Hero image Container */
.Hero__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
}
.Hero__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.Hero__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
}
.Header__inner,
.Text_HeaderContent {
  position: relative !important;
}
.Text_HeaderContent {
  padding: calc(4.4vw + 140px) 0;
}
.Header__inner h1,
.Text_HeaderContent h1 {
  font-weight: 900;
  width: 70%;
  font-size: 5.5rem;
  letter-spacing: -2px;
  line-height: 0.9em;
  margin-right: auto;
  padding-top: 37px;
  margin: 0;
  color: white;
}
.Header__inner h4 {
  color: #fff;
}
.Text_HeaderContent h1,
.Text_HeaderContent h4 {
  color: #2e2e2e;
}
.Text_HeaderContent h4,
.Header__inner h4 {
  font-family: "Hind Guntur";
  font-weight: 400;
  margin: 7px 0 0 0;
  width: calc(10vw + 35%);
  font-size: 1.3rem;
  line-height: 1.7rem;
}
.notification-banner {
  position: relative; 
  margin-top: 16px; 
  left: 50%; 
  transform: translateX(-50%); 
  font-size: 1.1rem; 
  color: #003366;
  font-family: "Hind Guntur"; 
  font-weight: 500;
  text-align: center; 
  padding: 8px 4px;
  background-color: rgba(230, 247, 255, 0.8); 
  border-radius: 5px;
  width: 100%;
  max-width: calc(100vw - 72px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  z-index: 12; 
}

@media screen and (min-width: 1480px) {
  .Hero {
    height: 650px;
  }
  .Text_TitleWrapper {
    width: 100%;
    transition: all 0.23s ease-out;
  }
  .Text_HeaderContent {
    padding: 200px 0;
  }
  .notification-banner {
    font-size: 1.2rem;
    /* padding: 20px 24px; */
    max-width: 100%;
    width: 100%;
    margin-top: 24px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Hero {
    height: calc(20vw + 354px);
  }
  .notification-banner {
    font-size: 1.1rem;
    /* padding: 18px 20px; */
    max-width: 95%;
    width: 100%;
    margin-top: 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .Hero {
    height: calc(18vw + 355px);
  }
  .Text_TitleWrapper::before {
    top: calc(20vw + 200px);
  }
  .Header__inner h1 {
    width: 70%;
    font-size: 4.5rem;
    letter-spacing: -2px;
    line-height: 0.9em;
  }
  .Header__inner h4 {
    font-size: 1.2rem;
  }
  .notification-banner {
    font-size: 1rem;
    padding: 7px 18px;
    max-width: 95%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 730px) and (max-width: 959px) {
  .Hero {
    height: calc(16vh + 340px);
  }
  .Header__inner h1,
  .Text_HeaderContent h1 {
    font-size: calc(6.5vw);
  }
  .Text_HeaderContent h4 {
    font-size: 1.2rem;
    line-height: 1.35rem;
    width: 50%;
  }
  .Header__inner h1 {
    width: 70%;
  }
  .Header__inner h4 {
    font-size: 1.1rem;
    line-height: 1.35rem;
    width: 80%;
  }
  .notification-banner {
    font-size: 0.8rem;
    padding: 9px 16px;
    max-width: 95%;
    margin-top: 14px;
  }
}
@media screen and (max-width: 729px) {
  .Hero__image img {
    width: auto;
    height: 100%;
  }
  .Header__inner h1,
  .Text_HeaderContent h1 {
    font-size: calc(2.5vw + 35px);
  }
  .Text_HeaderContent h4 {
    font-size: 1rem;
    line-height: 1.2rem;
    width: 70%;
  }
  .Header__inner h1 {
    width: 80%;
  }
  .Header__inner h4 {
    font-size: 1.1rem;
    line-height: 1.35rem;
    width: 100%;
  }
  .notification-banner {
    font-size: 0.5rem;
    padding: 4px 19px;
    max-width: 90%;
    margin-top: 2px;
    z-index: 12;
  }
}
