.MainHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 74px;
    background-color: transparent;
    z-index: 1100;
    transition: background-color .19s linear .2s; 
}

/* .active {
    transition: background-color .19s linear; 
    background-color: white;
} */