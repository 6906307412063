.LineGraph {
  /* border: 1px solid #e3e3e3;
  padding: 30px 30px 20px 0; */
  box-sizing: border-box;
}

.Tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 400;
}
