.HeaderItems {
  display: none;
}
.HeaderItem {
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.Item {
  display: block;
  position: relative;
  text-decoration: none;
  color: white;
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  margin: 23px 15px 20px 15px;
  padding: 0 15px;
  border-radius: 18px;
  /* transition: all 0.19s linear 0.2s; */
  transition: all 0.19s linear;
}
.active {
  transition: background-color 0.19s linear;
  background-color: rgba(255, 255, 255, 0.15);
}
.dark.active {
  transition: background-color 0.19s linear;
  background-color: rgba(0, 0, 0, 0.15);
}
.dark {
  color: black;
}
/* .Item.selected::before {
  position: absolute;
  top: -4px;
  right: -4px;
  left: -4px;
  bottom: -4px;
  content: "";
  opacity: 0;
  pointer-events: none;
  background: white;
  color: blue;
} */
/* .Item.active {
  transition: color 0.19s linear, opacity 0.19s linear;
  color: red;
} */
@media screen and (min-width: 960px) {
  .HeaderItems {
    display: flex;
  }
}
