.input-slider > input {
  width: 100%;
}
.input-slider {
  display: flex;
  align-items: center;
}
.input-slider span {
  /*margin-left: 10px;*/
  font-weight: 600;
  font-size: 1.25rem;
  /*padding-top: 5px;*/
}

.input-slider input[type=range] {
  height: 28px;
  -webkit-appearance: none;
  margin: 0px 0 10px 0;
  width: 100%;
}

.input-slider input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #E0E0E0;
  border-radius: 0px;
  border: 1px solid #000000;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: rgb(255,255,255);

}
.input-slider input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #FFFFFF;
  border: 0px solid #FFFFFF;
  height: 22px;
  width: 19px;
  border-radius: 4px;
  background: #DFB055;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}

.input-slider input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #FFFFFF;
  border: 0px solid #FFFFFF;
  height: 22px;
  width: 19px;
  border-radius: 4px;
  background: #DFB055;
  cursor: pointer;
}


/*.datalist {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: space-between;*/
/*  writing-mode: vertical-lr;*/
/*  width: 50px;*/
/*}*/



/* .RailStyle {
  position: absolute;
  width: 100%;
  height: 8px;
  margin-top: 16px;
  background: #a8a8a8 !important;
}

.dark {
  background-color: #fff !important;
} */
