.Header {
  z-index: 1;
  position: absolute;
  height: 100%;
}

.HeaderContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

.HeaderItems {
  background: #f4f4f4;
  box-sizing: border-box;
  padding: 20px;
}
.HeaderTitle {
  width: 100%;
  border-bottom: 1px solid #000;
}
.HeaderTitle h3 {
  padding: 10px 0;
  font-size: 2rem;
  width: 75%;
  line-height: 2.3rem;
  margin: 0;
}

@media screen and (min-width: 1480px) {
  .Header {
    margin-left: calc((100vw - 1384px) / 2);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Header {
    margin-left: calc((100vw - (100vw - 92px)) / 2);
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .Header {
    margin-left: calc((100vw - (100vw - 72px)) / 2);
  }
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 959px) {
  .Header {
    position: relative;
  }
  .HeaderContainer {
    display: flex;
    align-items: flex-start;
    background: #f4f4f4;
  }
  .HeaderItems {
    width: calc(100vw - 84px);
    padding: 20px 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .Header {
    position: relative;
  }
  .HeaderContainer {
    display: flex;
    align-items: flex-start;
    background: #f4f4f4;
  }
  .HeaderItems {
    width: calc(100vw - 48px);
    padding: 20px 0;
    margin: 0 auto;
  }
}
