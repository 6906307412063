.Container {
  display: grid;
  padding: 20px 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 35px auto;
  grid-auto-flow: dense;
  gap: 20px 20px;
}

.Item {
  grid-column: 3 span;
}

.Item > a {
  color: #6a6a6a;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.2px;
  width: 100%;
}

.Item > a > h4 {
  margin: 0;
  line-height: 1em;
  letter-spacing: -1px;
  font-size: 2rem;
  color: #2e2e2e;
}

.Item > a > p {
  font-size: 1rem;
  margin: 20px 0 0 0;
  line-height: 1em;
}

.Item:hover a h4 {
  text-decoration: underline;
}


.subscribe{
  grid-column: 3 span;
  margin-left: 5%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.5rem;
}
.subscribe a{
  font-size: 1rem;
  padding: 5px 10px 2px 10px;
  text-decoration: none;
  color: #000;
  background: #eac070;
}


.Text {
  /* grid-column: span 6; */
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.003rem;
}

.AboutText {
  /* grid-column: span 6; */
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.003rem;
  margin-bottom: 25px;
}

/* Layout */
@media screen and (max-width: 1023px) {
  .Container {
    padding: 30px 0;
  }
  .Item {
    grid-column: span 6;
  }
}

@media screen and (max-width: 729px) {
  .Item {
    grid-column: span 12;
    line-height: 25px;
  }

  .Item > a > h4 {
    font-size: 1.6rem;
  }

  .Item > a {
    font-size: 1rem;
  }
}
