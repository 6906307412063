/* @@@ GRIDs STYLE @@@ */
.Grid {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* border-right: 1px solid #e3e3e3; */
}

.main {
  margin-top: -100px;
  position: relative;
  z-index: 10;
  border: none !important;
}

.Grid:last-child {
  border-right: none;
}

.Grid > a {
  color: #2e2e2e;
  /* padding-right: 16px; */
  margin-top: 16px;
  display: inline-block;
}
.Grid > a:hover h4 {
  text-decoration: underline;
}

/* @@@ GRID HEADER STYLE @@@ */
.GridHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c3533e;
}
.GridHeader > a {
  color: #c3533e;
}
.GridHeader_twelve {
  grid-column: 1 / -1;
  border-top: 2px solid #939393;
  border-bottom: 1px solid #e3e3e3;
}
.GridHeader_nine {
  grid-column: 1 / 10;
  border-top: 2px solid #939393;
  border-bottom: 1px solid #e3e3e3;
}

.GridHeader_latest {
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}

.GridHeader > h3,
.GridHeader > span {
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
}

.GridHeader > span {
  text-transform: none;
}
.item__disabled {
  pointer-events: none;
  color: #b3b3b3 !important;
}
/* @@@ GRID IMAGE @@@ */
.GridImage {
  width: 100%;
  padding-bottom: 70%;
  height: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  background: lightgray;
}
.GridImage img {
  width: 100%;
}
.GridNewsletterImage {
  width: 100%;
  padding-bottom: 39%;
  height: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  background: lightgray;
}
.GridNewsletterImage img {
  width: 100%;
}

.GridLibraryImage img {
  width: 100%;
  height: 100%;
}

.mainimage {
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}
.GridVideo {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;
  background: lightgray;
}
.GridVideo > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.AboutContainer {
  width: 100%;
  display: flex;
  margin: 45px 0 100px 0;
}
.AboutTextContainer {
  flex: 1 1;
  /* width: 50%; */
}
.AboutImageContainer {
  width: 100%;
  flex: 1 1;
  padding-left: 30px;
  box-sizing: border-box;
}
.AboutImage {
  width: 100%;
  height: 100%;
  /* background: #e3e3e3; */
}
.MembersWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.ImageMembers {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40%;
  max-width: 45%;
  flex: 1 1;
  height: auto;
  margin: 10px;
  padding: 5%;
  box-sizing: border-box;
}

.GridImageAboutMultiple {
  display: -webkit-flex;
  height: 100px;
  margin-bottom: 20px;
  margin-left: 15px;
}
.GridImageAboutMembers {
  display: flex;
  max-height: 80px;
  max-width: 160px;
  margin: auto;
}
.GridImageAboutBox {
  flex: 1 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 3px 1px -2px rgb(0 0 0 / 12%);
  margin: auto;
  padding: 6%;
  height: 70px;
  width: 100px;
}

.GridImageAboutMembersWrap {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: wrap;
}

/* @@@ GRID CONTENT STYLE @@@ */
.GridContent > p {
  margin: 15px 0 0 0;
  color: #525252;
  line-height: 1.3em;
  font-size: 1.1rem;
}

.GridContent > h4 {
  margin: 0;
  line-height: 1em;
  letter-spacing: -1px;
}

.GridContent > h5 {
  margin: 5px 0 0 0;
  line-height: 1em;
  font-size: 1rem;
  letter-spacing: -1px;
}

/* @@@ GRID TYPE @@@ */
/* .sm, */
.md {
  grid-row: 2 / -1;
}
.xs {
  grid-column: span 2;
  padding: 7px;
}
.sm {
  grid-column: span 3;
}
.md {
  grid-column: span 4;
}
.lg {
  grid-column: span 6;
}

/* Image  */
@media screen and (max-width: 768px) {
  .AboutContainer {
    flex-flow: column;
  }
  .AboutImageContainer {
    padding-left: 0;
  }
  .ImageMembers {
    padding: 3%;
  }
}
@media screen and (max-width: 500px) {
  .AboutImageContainer {
    padding-left: 0;
  }
  .ImageMembers {
    padding: 2%;
  }
}

@media screen and (min-width: 1480px) {
  .GridContent > h4 {
    font-size: 2rem;
  }
}
/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .GridContent > h4 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .GridContent > h4 {
    font-size: 1.75rem;
  }
}

/* Layout */
@media screen and (max-width: 1023px) {
  .GridHeader {
    padding-top: 5px;
  }
  .GridContent > p {
    display: none;
  }
  .GridHeader > h3,
  .GridHeader > span {
    font-size: 0.9rem;
    margin: 0;
    text-transform: uppercase;
  }
  .GridHeader_nine {
    grid-column: 1 / 9;
    grid-row: 1 / span 1;
  }
  .md {
    grid-column: span 4;
    /* border-right: 1px solid #e3e3e3; */
  }
  .sm {
    grid-column: span 6;
  }
  .xs {
    grid-column: 4 span;
  }
  .md:last-child,
  .sm:nth-child(3) {
    border-right: none;
  }
  .GridContent > h4 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 729px) {
  .Grid {
    border-right: none;
  }
  .GridImage {
    padding-bottom: 50%;
  }
  .Grid > a {
    padding: 0;
    margin: 0;
  }
  .main:not(:first-child) {
    margin-top: 0;
  }
  .md > a,
  .lg > a {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .xs {
    grid-column: 12 span;
  }
  .md,
  .lg {
    border-top: 1px solid #e3e3e3;
  }
  .GridHeader_nine {
    grid-column: 1 / -1;
  }
  .sm,
  .md,
  .lg {
    grid-column: 1 / -1;
    grid-row: auto;
  }
  .GridContent > h4 {
    font-size: 1.6rem;
  }
  .GridHeader_twelve {
    /*grid-column: 1 / -6;*/
    border-top: 2px solid #939393;
    border-bottom: 1px solid #e3e3e3;
  }
}
