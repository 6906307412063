.ModalText {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    margin-top: 30px;
    transition: max-height 0.3s ease-out, opacity .19s linear;
    max-height: 0;
}

.ModalText p {
    font-size: 1.2rem;
    width: 55%;
    margin-right: auto;
    line-height: 1.4em;
}

.show { 
    opacity: 1;
    max-height: 2000px;
    visibility: visible;
    transition: max-height 0.3s ease-out, opacity .19s linear .3s;
}


@media screen and (min-width: 960px) and (max-width: 1279px)  {
    .ModalText p {
        width: 60%;
    }
}

@media screen and (min-width: 730px) and (max-width: 959px)  {
    .ModalText p {
        width: 80%;
    }
}

@media screen and (max-width: 729px) {
    .ModalText p {
        width: 100%;
    }
}
