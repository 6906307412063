.Container {
  display: grid;
  padding: 70px 0;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: 35px auto;
  grid-auto-flow: dense;
  gap: 10px 10px;
}
.Item {
  width: 100%;
}
.Item img {
  height: 450px; /* fix one height */
  width: auto; 
  object-fit: cover; 
}
.Item > a {
  color: #6a6a6a;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 1.2rem;
  width: 100%;
}
.Item > a:hover {
  color: #2e2e2e;
  text-decoration: underline;
}
.button {
  border: none;
  padding: 3px 7.5px 4px 7.5px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: #c3533e;
  border: 1px solid #c3533e;
  color: white;
  transition: all 0.19s linear;
  outline: none;
  margin-right: 5px;
}
.button:hover {
  font-weight: 600;
  background-color: transparent;
  transition: all 0.19s linear;
  outline: none;
}
.download__inner {
  display: flex;
  flex-flow: column;
}
.download__inner span {
  font-size: 1.25rem;
  font-weight: 600;
}
.download__item {
  margin-bottom: 35px;
}
.download__item a {
  padding: 8px 14px 4px 14px;
  text-decoration: none;
  color: #000;
  background: #eac070;
  margin-right: 20px;
}
@media screen and (min-width: 1480px) {
  .Item {
    grid-column: span 3;
  }
}
/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Item {
    grid-column: span 3;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .Item {
    grid-column: span 3;
  }
}
/* Layout */
@media screen and (min-width: 730px) and (max-width: 1023px) {
  .Container {
    padding: 30px 0;
  }
  .Item {
    grid-column: span 3;
  }
}
@media screen and (max-width: 729px) {
  .Container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
  }
  .Container .MenuItem {
    line-height: 0.2; 
    letter-spacing: 0.1em; 
    padding: 0 10px; 
  }
  .Container .MenuItem a {
    display: inline-block; 
    margin: 0 3px; 
    padding: 0 10px; 
  }
  .Item {
    grid-column: span 1.5;
    line-height: 25px;
  }
  .Item > a {
    font-size: 1rem;
  }
  .Item img {
    height: 180px; 
    width: auto; 
    object-fit: cover; 
  }
}
