.bar {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
  justify-content: flex-end;
}
.ExploreMixerOpen {
  position: fixed;
  width: 375px;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background: white;
  z-index: 12;
  transform: translate3d(0, 0, 0);
  transition: transform 0.29s linear 0.1s;
  flex-flow: column;
  padding: 16px 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-right: 44px;

}

.ExploreMixerClosed {
  position: fixed;
  width: 375px;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background: white;
  z-index: 12;
  transform: translate3d(0, 0, 0);
  transition: transform 0.29s linear 0.1s;
  flex-flow: column;
  padding: 16px 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding-right: 44px;
  left:-355px;

}


.ExploreTimeslider {
  width: 100%;
  display: flex;
  flex-flow: column;
  /*margin-top: 20px;*/
}
.ExploreTimeslider span {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
}
.ExploreSearch {
  margin-top: 20px;
}
.ExploreDivider {
  border-top: 1px solid #535353;
  width: 100%;
  margin: 0 0 30px 0;
}

.BarWrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.closed {
  transform: translate3d(-100%, 0px, 0px);
  transition: transform 0.29s linear 0.1s;
}
.button_menu {
  position: absolute;
  margin-left: 20px;
  top: 50%;
  left: 375px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  padding: 6px 15px 8px 15px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: #c3533e;
  border: 2px solid #c3533e;
  color: white;
  transition: all 0.19s linear;
  outline: none;
}
.button_menu:hover {
  color: #c3533e;
  background-color: transparent;
  transition: all 0.19s linear;
}
.HistogramItems {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -ms-flexbox-flex-wrap: wrap;
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-flex-direction: row;
  -ms-flexbox-flex-direction: row;
  border-bottom: 1px solid #e3e3e3;
  margin: auto;
  font-size: 0.9rem;
  line-height: 19px;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.HistorgramItems:last-child {
  border-bottom: 1px solid #e3e3e3;
}
.ExploreMixerSelectors {
  display: flex;
}
.ExploreMixerSelector {
  cursor: pointer;
  opacity: 0.5;
  font-weight: 600;
  margin-right: 10px;
}
.selected {
  opacity: 1;
}
.Histogram_Total {
  min-width: 27%;
  text-align: right;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
  justify-content: flex-end;
}
.Histogram_Total_Africa {
  min-width: 100%;
  padding-top: 25px;
  font-size: 1.7rem;
  text-align: right;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
  justify-content: space-around;
}
.HistogramHeader {
  font-weight: 600;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  justify-content: space-between;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
}
.HistogramContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.CountryHistogramItem_Title_Africa {
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
}
.CountryRanking {
  align-self: flex-end;
}
.CountryRanking span {
  font-weight: 600;
}
.invisible {
  display: none;
}

.viewType {
  display: flex;
  flex-flow: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 20px;
}
.viewType__item {
  border: none;
  padding: 4px 15px 0 15px;
  font-weight: 100;
  /*margin: 0;*/
  font-size: 1rem;
  cursor: pointer;
  background: #fff;
  border: 1px solid #bdbdbd;
  color: #7e7e7e;
  transition: all 0.19s linear;
  outline: none;
  font-family: 'Hind Guntur', san-serif;
  white-space: nowrap;
}

.viewType__item_fr {
  border: none;
  padding: 4px 8px 0 8px;
  font-weight: 100;
  /*margin: 0;*/
  font-size: 1rem;
  cursor: pointer;
  background: #fff;
  border: 1px solid #bdbdbd;
  color: #7e7e7e;
  transition: all 0.19s linear;
  outline: none;
  font-family: 'Hind Guntur', san-serif;
  white-space: nowrap;
}


.viewType__item:not(:first-child) {
  margin-left: -1px;
}
.viewType__item:hover {
  background: #eabf70b0;
  transition: all 0.19s linear;
  outline: none;
}
.viewType__item_selected {
  background: #eac070;
  color: #000;
}
.viewType__item:disabled {
  background: #e3e3e3;
  color: #7e7e7e;
  cursor: not-allowed;
}

.CriterionHeader {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex: 1;
  justify-content: space-between;
  -webkit-box: 1;
  -moz-box: 1;
  -ms-flexbox: 1;
  -webkit-flex: 1;
}

.BuiltUpArea{
  padding: 15px 0 0 10px;
  display: flex;
  align-items: baseline;
}

.UrbanPopulationStatistics{
  padding: 0 0 5px 10px;
  display: grid;
  /*align-items: baseline;*/
}

.Layer{
  padding: 0px 0 5px 10px;
  display: grid;
  align-items: baseline;
}
.LayerButtons{
  padding: 0px 0 10px 0px;
  display: flex;
  align-items: baseline;
}


.modal {
  font-size: 18px;
  -webkit-animation: anvil 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 25px;
  text-align: center;
  padding: 5px;
}
.content {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  /*margin : 30px;*/
  justify-content : space-between;
}
.content input[type="text"] {
  padding: 2px 2px;
  line-height: 28px;
  margin: 0 5px 10px 0px;
  width: 70%;
}
.content div{
  padding : 10px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.backshare{
  display: flex;
  justify-content : space-between;
  /*margin: 0px 10px 0px 10px;*/
}
.a {
  color: #000000;
}
