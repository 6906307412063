.MobileNav {
  display: none;
  position: fixed;
  display: block;
  min-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  width: 375px;
  background: white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.29s linear 0.1s, visibility 0ms 0.2s;
  z-index: 1100;
}

.MobileHeader {
  height: 68px;
  display: flex;
  justify-content: flex-end;
}
.MobileHeader::before {
  position: absolute;
  content: "";
  width: 100%;
  top: 68px;
  height: 1px;
  background: #e3e3e3;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.29s linear;
}

.MobileWrapper {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.Mobile_Backward {
  display: none;
}

.MobileHeaderCurrent {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileHeaderCurrent span {
  display: none;
  padding-top: 5px;
  font-size: 1.2rem;
  font-weight: 500;
}

.show {
  display: block !important;
}

@media screen and (max-width: 959px) {
  .MobileNav {
    width: 375px;
  }
  .open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.29s linear;
  }
}

@media screen and (max-width: 501px) {
  .MobileNav {
    width: 100%;
  }
}
