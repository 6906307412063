.visualisation__inner {
  width: 100%;
  border: 1px solid #e3e3e3;
  padding: 20px 20px;
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  justify-content: space-between;
}
.viewType {
  display: flex;
  flex-flow: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 20px;
}
.viewType__item {
  border: none;
  padding: 10px 14px;
  margin: 0;
  font-size: 0.8rem;
  cursor: pointer;
  background: #fff;
  border: 1px solid #bdbdbd;
  color: #000;
  transition: all 0.19s linear;
  outline: none;
}
.viewType__item:not(:first-child) {
  margin-left: -1px;
}
.viewType__item:hover {
  background: #eabf70b0;
  transition: all 0.19s linear;
  outline: none;
}
.selected {
  background: #eac070;
  color: #000;
}
.viewType__item:disabled {
  background: #e3e3e3;
  color: #7e7e7e;
  cursor: not-allowed;
}

@media screen and (max-width: 959px) {
  .viewType {
    flex-flow: column;
    align-items: center;
  }
}
@media (max-width: 752px) {
  .visualisation__inner {
    box-sizing: border-box;
  }
  .KeyFigure__Md_Lg {
    display: none;
  }
}
