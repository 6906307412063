.button {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  padding: 6px 14px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: #c3533e;
  border: 2px solid #c3533e;
  color: white;
  transition: all 0.19s linear;
  outline: none;
}

.button:hover {
  color: #c3533e;
  background-color: transparent;
  transition: all 0.19s linear;
  outline: none;
}

.close {
  font-size: 1rem;
  font-weight: 600;
  border: none;
  padding: 3px 7.5px 4px 7.5px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: "grey";
  border: 2px solid "grey";
  color: white;
  transition: all 0.19s linear;
  outline: none;
}

.africa {
  font-size: 1rem;
  font-weight: 600;
  border: none;
  padding: 3px 7.5px 4px 7.5px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 0.7px;
  cursor: pointer;
  background-color: #eac070;
  border: 2px solid #eac070;
  color: white;
  transition: all 0.19s linear;
}

.africa:hover {
  color: #c3533e;
  background-color: transparent;
  transition: all 0.19s linear;
}
