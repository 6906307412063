.cr_map__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}
@media screen and (max-width: 959px) {
  .cr_map__wrapper {
    /* margin-left: calc((100vw - (100vw - 72px)) / 2); */
    height: 400px;
    position: relative;
  }
}
body > iframe { display: none } 