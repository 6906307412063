.MobileHeaderButton {
  display: none;
  align-items: center;
}
.MobileHeaderButton button {
  margin: 0 !important;
}
@media screen and (max-width: 959px) {
  .MobileHeaderButton {
    display: flex;
  }
}
