.ReportWrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(15vw + 400px);
}

.Header {
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  background-color: #eac070;
}
.CountryReport__search {
  width: 25vw;
  min-width: 260px;
  max-width: 350px;
  display: flex;
  align-items: center;
}
.CountryReportNavToggle {
  float: right;
}
.CountryReportNav {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  display: flex;
  height: 68px;
  background: #fff;
  border-bottom: 1px solid #e3e3e3;
  top: 0;
  z-index: 50;
}
.CountryReportNav__inner {
  width: calc(100vw - 84px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.active {
  transition: background-color 0.19s linear;
  background-color: white;
}
.HeaderItem {
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}

.selected {
  border-bottom: 3px solid #11bb9a;
  color: #11bb9a;
}

.materialIcons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 64px; /* Preferred icon size */
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: black;
  cursor: default;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.arrowTop,
.arrow {
  position: fixed;
  left: 50%;
  bottom: -10%;
}

.arrowTop a,
.arrow a {
  z-index: 2;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  padding-top: 55px;
}

.arrowTop a:hover,
.arrow a:hover {
  opacity: 0.5;
}

.arrowTop a span,
.arrow a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 1px solid #000;
  border-radius: 100%;
  box-sizing: border-box;
}

.arrowTop a span::after,
.arrow a span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.arrowTop a span::before,
.arrow a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  content: "";
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}

.arrowTop a span::after {
  position: absolute;
  top: 70%;
  left: 50%;
  content: "";
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 1480px) {
  .Header {
    width: 1384px;
  }
  .CountryReportNav__inner {
    width: 1384px;
  }
}

/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Header {
    width: calc(100vw - 92px);
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .Header {
    width: calc(100vw - 72px);
  }
}

/* MOBILE */
@media screen and (max-width: 1279px) {
  .arrowTop,
  .arrow {
    z-index: 99999;
    position: fixed;
    left: 40%;
    top: 0%;
    width: 450px;
  }

  .arrowTop a span,
  .arrow a span {
    left: 20%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #000;
    border-radius: 100%;
    box-sizing: border-box;
  }

  .arrowTop a,
  .arrow a {
    z-index: 99999;
    -webkit-transform: translate(0, 0%);
    transform: translate(0, 0%);
    font: normal 400 16px/1 "Josefin Sans", sans-serif;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;
    padding-left: 100px;
    padding-top: 10px;
  }

  .arrowTop a:hover,
  .arrow a:hover {
    opacity: 0.95;
  }
}
@media screen and (max-width: 959px) {
  .Header {
    width: calc(100vw - 48px);
  }
  .ReportWrapper {
    /* height: 400px; */
    height: unset;
    min-height: unset;
    max-height: unset;
    position: relative;
    background: white;
  }
}

@media screen and (max-width: 767px) {
  .CountryReportNav__inner {
    width: calc(100vw - 48px);
  }
  .CountryReport__search {
    width: 50vw;
    min-width: 220px;
    max-width: unset;
  }
}

@media screen and (max-width: 600px) {
  .arrowTop,
  .arrow {
    z-index: 99999;
    position: fixed;
    left: 0%;
    top: 0%;
    width: 450px;
  }

  .arrowTop a span,
  .arrow a span {
    left: 0%;
    width: 46px;
    height: 46px;
    margin-left: -23px;
    border: 1px solid #000;
    border-radius: 100%;
    box-sizing: border-box;
  }

  .arrowTop a,
  .arrow a {
    padding-left: 50px;
  }
}
