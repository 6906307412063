.Header {
  /* width: 1384px; */
  height: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: space-between;
}
nav {
  height: inherit;
}
nav > ul {
  display: flex;
  height: inherit;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
}
.Logo {
  height: 100%;
  display: flex;
  align-items: center;
}
.Logo img {
  position: absolute;
  width: unset;
  height: 65%;
}
.Main__nav:first-child {
  transition: opacity 0.19s linear 0.2s;
  opacity: 0;
}
.Main__nav:last-child {
  transition: opacity 0.19s linear 0.2s;
  opacity: 1;
}
.active:first-child {
  transition: opacity 0.19s linear;
  opacity: 1;
}
.active:last-child {
  transition: opacity 0.19s linear;
  opacity: 0;
}
.active {
  transition: background-color 0.19s linear;
  background-color: white;
}
@media screen and (min-width: 1480px) {
  .Header {
    width: 1384px;
  }
}
/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px) {
  .Header {
    width: calc(100vw - 92px);
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .Header {
    width: calc(100vw - 72px);
  }
}
/* Tablets */
@media screen and (min-width: 730px) and (max-width: 959px) {
  .Header {
    width: calc(100vw - 72px);
  }
}
/* MOBILE */
@media screen and (max-width: 729px) {
  .Header {
    width: calc(100vw - 48px);
  }
  .Logo img {
    position: absolute;
    height: 50%;
  }
}
