.cr_content_offset {
  width: 100%;
  margin-top: calc(15vw + 400px);
}
.cr_content_wrapper {
  width: 100%;
}
.cr_content_wrapper {
  border-bottom: 2px solid #e3e3e3;
}
.cr_content_container {
  width: calc(100vw - 84px);
  margin: auto;
}
.cr_content_section {
  width: 100%;
}
.cr_content_section__inner {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 100px 0;
}
.cr_content_section__graph {
  width: 100%;
  height: 100%;
  margin-top: 45px;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cr_content_section__text {
  padding-right: 30px;
  width: calc((100% / 16 * 8) + 50px);
  box-sizing: border-box;
}
.cr_content_text__title {
  font-size: 2.75rem;
  font-weight: 700;
  letter-spacing: -0.02rem;
}
.cr_content_text__content {
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.003rem;
}
.cr_content_text__content p {
   margin-bottom: 24px;
 }
.cr_content_text__content a {
  display : inline;
}
.cr_content_text__content a:link {
  color: #909090;
}
.cr_content_text__content a:hover {
  color: #252a5f;
  text-decoration: underline;
}
.cr_content_text__content a:visited {
  color: #909090;
}

@media screen and (min-width: 1480px) {
  .cr_content_container {
    width: 1384px;
  }
}
@media screen and (min-width: 768px) and (max-width: 959px) {
  .cr_content_section__text {
    width: 100%;
  }
  .cr_content_offset {
    position: relative;
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .cr_content_container {
    width: calc(100vw - 48px);
  }
  .cr_content_offset {
    position: relative;
    margin-top: 0;
  }
  .cr_content_section__text {
    width: 100%;
    padding: 0;
  }
}
