.Legend {
  position: absolute;
  margin-right: 20px;
  margin-bottom: 30px;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.LegendContent {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  padding: 18px 14px;
  box-sizing: border-box;
}
.LegendTable {
  font-size: 0.9rem;
}
.LegendRow {
  cursor: pointer;
}
.LegendRow > td:not(:last-child) {
  padding-right: 8px;
}
.LegendRow:hover {
  text-decoration: underline;
}
.LegendSymbol {
  border-radius: 50%;
}
.LegendSymbolWrapper {
  display: flex;
  width: 28px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
