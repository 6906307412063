.Button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  /*margin: 12px 14px 0 0;*/
}
.dark {
  color: #fff;
}
.icon--ham,
.icon--ham svg {
  width: 20px;
  height: 17px;
}
.icon--close-btn,
.icon--close-btn svg {
  width: 17px;
  height: 17px;
}
.icon--arrow-left,
.icon--arrow-left svg {
  width: 45px;
  height: 45px;
}

.icon--share,
.icon--share svg {
  width: 33px;
  height: 33px;
}
