.Wrapper {
  padding: 25px 25px;
}
.main {
  padding: 0;
  background: #e3e3e3;
}
.intro,
.outro {
  border-bottom: 1px solid #e3e3e3;
}

@media screen and (max-width: 729px) {
  .Wrapper {
    padding: 25px 25px;
  }
  .intro,
  .outro {
    border-bottom: 1px solid #e3e3e3;
  }
}
