.ArticleWrapper {
  top: 0;
  left: 0;
}

.ArticleContainer {
  padding-top: 230px;
  padding-bottom: 400px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: dense;
  gap: 10px 20px;
}

.ArticleContainer header {
  width: 100%;
  margin: 0 0 40px 0;
  grid-column: 4 / span 6;
}

.ArticleContainer header h1 {
  margin: 0;
  font-size: 4.5rem;
  letter-spacing: -2px;
  line-height: 1em;
}

@media screen and (min-width: 730px) and (max-width: 1024px) {
  .ArticleContainer header {
    grid-column: 1 / span 12;
  }
  .ArticleContainer header h1 {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 729px) {
  .ArticleContainer header {
    grid-column: 1 / span 12;
  }
  .ArticleContainer header h1 {
    font-size: 2.5rem;
  }
}
