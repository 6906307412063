body {
  margin: 0;
  font-family: 'Hind Guntur', san-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h4 {
  font-family: 'Heebo', san-serif;
}

html {
  width: 100vw;
}

a { text-decoration: none; display: inline-block;}

ul {
  list-style: none;
  padding: 0;
}

p {
  margin: 0;
}

.icon__chevron_right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;  /* Preferred icon size */
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: #b1b1b1;
  cursor: default;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


@media screen and (min-width: 1480px) {
  .container {
      width: 1384px;
      margin: 0 auto;
  }
}



/* LARGER SIZE TABLETS */
@media screen and (min-width: 1280px) and (max-width: 1479px)  {
  .container {
      width: calc(100vw - 92px);
      margin: 0 auto;
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px)  {
  .container {
      width: calc(100vw - 72px);
      margin: 0 auto;
  }
}

/* Tablets */
@media screen and (min-width: 730px) and (max-width: 959px)  {
  .container {
      width: calc(100vw - 72px);
      margin: 0 auto;
  }
}

/* MOBILE */
@media screen and (max-width: 729px)  {
  .container {
      width: calc(100vw - 48px);
      margin: 0 auto;
  }
}

.d3plus-tooltip {
  z-index:1000;
}
